import type { Page } from '@charmverse/core/prisma';
import { getPagePath } from '@root/lib/pages/utils';

export function untitledPage({ userId, spaceId }: { userId: string; spaceId: string }): Partial<Page> {
  return {
    createdBy: userId,
    autoGenerated: true,
    contentText: '',
    path: getPagePath(),
    title: '',
    type: 'page',
    updatedAt: new Date(),
    updatedBy: userId,
    spaceId
  };
}
